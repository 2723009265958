import {store} from '../../store/store';
import i18n from 'i18next';
import {saveProfileInLocalStorage, sendPushToken} from "../../services/AuthService";

export function restaurantHasDelivery() {
  if (!!store.getState().auth.auth.restaurant) {
    return Object.values(store.getState().auth.auth.restaurant.order_types['delivery']).includes(true)
  } else {
    return false;
  }
}
export function restaurantHasQRpay() {
  if (!!store.getState().auth.auth.restaurant) {
    return store.getState().auth.auth.restaurant.qr_pay;
  } else {
    return false;
  }
}

export function getDistanceInKm(point1, point2) {
  const lat1 = parseFloat(point1.lat);
  const lng1 = parseFloat(point1.lng);
  const lat2 = parseFloat(point2.lat);
  const lng2 = parseFloat(point2.lng);
  const earthRadiusKm = 6371;
  const latDiffInKm = (lat2 - lat1) * (Math.PI / 180) * earthRadiusKm;
  const lngDiffInKm = (lng2 - lng1) * (Math.PI / 180) * earthRadiusKm * Math.cos((lat1 + lat2) * (Math.PI / 360));
  return Math.hypot(latDiffInKm, lngDiffInKm);
}

export function translateRecord(item, field) {
  if (i18n.language === 'en') {
    return !!item[`${field}_en`] ? item[`${field}_en`] : item[field];
  } else {
    return item[field];
  }
}

export function isFutureTime(time) {
  const selectedTime = new Date();
  selectedTime.setHours(time.getHours());
  selectedTime.setMinutes(time.getMinutes());
  return new Date().getTime() < selectedTime.getTime();
}

export function getPackingPrice(slug) {
  let result = 0;
  const r = store.getState().orders.orderDishes[slug];
  if (!!r) {
    const dishOrders = Object.values(r.dishes);
    result += dishOrders.filter((d) => !d.package_capacity || d.package_capacity === 1).map((d) => boxPrice(d) * (calculateQuantity(d) || 1)).reduce((partialSum, a) => partialSum + a, 0);
    const groupPackages = groupBy(dishOrders.filter((d) => d.package_capacity > 1), 'sub_category_id');
    result += Object.values(groupPackages).map((dishes) =>
      Math.ceil(dishes.map((d) => calculateCapacity(d)).reduce((partialSum, a) => partialSum + a, 0)) *
      boxPrice(dishes[0])).reduce((partialSum, a) => partialSum + a, 0);
    if (!!r.restaurant.cutleryOrders && store.getState().orders.orderCheckout.cutleries) {
      result += Object.values(r.restaurant.cutleryOrders).map((c) => c.price * c.quantity).reduce((partialSum, a) => partialSum + a, 0);
    }
    if (dishOrders.map((d) => d.categoryPackage).includes(true)) {
      return (result + r.restaurant.package_price);
    }
  }
  return result;
}

function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    if (!!x[key]) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
    } else {
      (rv[`${x['category_dish_id']}_cd`] = rv[`${x['category_dish_id']}_cd`] || []).push(x);
    }
    return rv;
  }, {});
}

export function orderSummary(s) {
  const storeOrders = store.getState().orders;
  if (!!storeOrders.orderDishes[s]) {
    let result = dishesAmount(s);
    if (storeOrders.orderCheckout.selectedOrderType === 'to_go' ||
        storeOrders.orderCheckout.selectedOrderType === 'delivery') {
      return result + getPackingPrice(s);
    } else {
      return result;
    }
  } else {
    return 0;
  }
}

export function dishesAmount(s) {
  const storeOrders = store.getState().orders;
  const restaurant = storeOrders.orderDishes[s];
  if (!!restaurant) {
    let summary = [];
    let checkedCategories = [];
    Object.values(restaurant.dishes).forEach((dish) => {
      if (!!dish.selectedVariation) {
        if (!checkedCategories.includes(dish.category_dish_id) && !!dish.categoryPrice) {
          checkedCategories.push(dish.category_dish_id);
          summary.push(parseFloat(dish.categoryPrice));
        } else if (!dish.categoryPrice) {
          summary.push(parseFloat(dish.selectedVariation.price) * parseFloat(dish.quantity));
        }
      } else {
        summary.push(calculatePrice(dish))
      }
      if (!!dish.selectedMods) {
        Object.values(dish.selectedMods).forEach((mod) =>
          summary.push(parseFloat(mod.price) * parseFloat(mod.quantity) * parseFloat(dish.quantity))
        )
      }
    });
    return summary.reduce((partialSum, a) => partialSum + a, 0);
  } else {
    return 0;
  }
}

function calculatePrice(dish) {
  if (dish.weight_flag) {
    return (parseFloat(dish.price) / parseFloat(dish.weight)) * parseFloat(dish.quantity)
  } else {
    return parseFloat(dish.price) * parseFloat(dish.quantity);
  }
}

function calculateCapacity(dish) {
  if (!!dish.dish_restaurant_id) {
    return (1/dish.package_capacity) * (calculateQuantity(dish) || 1);
  } else {
    return dish.weight_flag ?
      dish.quantity/dish.package_capacity : (1/dish.package_capacity) * (calculateQuantity(dish) || 1);
  }
}

function calculateQuantity(dish) {
  return dish.weight_flag && !dish.dish_restaurant_id ?
    parseFloat(dish.quantity)/parseFloat(dish.weight) : parseFloat(dish.quantity);
}

function boxPrice(dish) {
  return parseFloat(dish.box.price) || 0
}

export function isOrderActive(o) {
  return o.status === 'created' || o.status === 'payed' || o.status === 'cooking' || o.status === 'ready' ||
    o.status === 'on_delivery';
}

export const payCheckDishes = (payCheck) => {
  if (!!payCheck) {
    return Object.values(payCheck.orders).reduce((acc, order) => {
      const guestId = order.guest_id;
      if (!acc[guestId]) {
        acc[guestId] = [];
      }
      acc[guestId].push(order);
      return acc;
    }, {});
  } else {
    return [];
  }
};

export function isIOSWebView() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log('userAgent', userAgent);
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    if (!/Safari/.test(userAgent)) {
      return true;
    }
  }
  return false;
}

export function isMobileSafari() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if ((/iPhone|iPad/i.test(userAgent)) && !window.MSStream) {
    if (/Safari/.test(userAgent) && !/CriOS/.test(userAgent) && !/FxiOS/.test(userAgent)) {
      return true;
    }
  }
  return false;
}

export function isWindowsOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log('userAgent', userAgent);
  // This regular expression checks for the "Windows" keyword in the userAgent string.
  return /Windows/.test(userAgent);
}

export function orderButton(order) {
  let statuses = {};
  if (order.kind === 'delivery') {
    statuses = {
      created: {action: 'cook', name: 'Готувати', status: 'Створено', color: 'btn-info'},
      payed: {action: 'cook', name: 'Готувати', status: 'Створено', color: 'btn-info'},
      cooking: {action: 'cooked', name: 'Приготовлено', status: 'Готується', color: 'btn-success'},
      ready: {action: 'delivering', name: 'В дорозі', status: 'Приготовлено', color: 'btn-danger'},
      on_delivery: {action: 'done', name: 'Завершено', status: 'В дорозі', color: 'btn-dark'},
      completed: {action: 'completed', name: 'Завершено', status: 'Завершено', color: 'btn-dark'},
      declined_by_restaurant: {action: 'restaurant_decline', name: 'Скасовано', status: 'Скасовано', color: 'btn-light'},
      declined_by_user: {action: 'restaurant_decline', name: 'Скасовано', status: 'Скасовано', color: 'btn-light'},
      refunded: {action: 'refund', name: 'Повернено', status: 'Повернено', color: 'btn-light'}
    };
  } else {
    statuses = {
      created: {action: 'cook', name: 'Готувати', status: 'Створено', color: 'btn-info'},
      payed: {action: 'cook', name: 'Готувати', status: 'Створено', color: 'btn-info'},
      cooking: {action: 'cooked', name: 'Приготовлено', status: 'Готується', color: 'btn-success'},
      ready: {action: 'done', name: 'Завершити', status: 'Приготовлено', color: 'btn-dark'},
      on_delivery: {action: 'completed', name: 'В дорозі', status: 'В дорозі', color: 'btn-dark'},
      completed: {action: 'completed', name: 'Завершено', status: 'Завершено', color: 'btn-dark'},
      declined_by_restaurant: {action: 'restaurant_decline', name: 'Скасовано', status: 'Скасовано', color: 'btn-light'},
      declined_by_user: {action: 'restaurant_decline', name: 'Скасовано', status: 'Скасовано', color: 'btn-light'},
      refunded: {action: 'refund', name: 'Повернено', status: 'Повернено', color: 'btn-light'}
    };
  }
  return statuses[order.status];
}

export function sendPushDeviceToken(token, device) {
  console.log('current token for client: ', token);
  localStorage.setItem(`${device}Token`, token);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const userDevices = !!userDetails.userData && !!userDetails.userData.devices;
  if (!!userDevices && !userDetails.userData.devices.includes(token)) {
    return sendPushToken(token, device).then((resp) => {
      saveProfileInLocalStorage(resp.data.user);
    }).catch((err) => {
      console.log('An error occurred while sending token. ', err);
    });
  }
}

export function alreadyPaidPayCheck(payCheck) {
  return payCheck.status === 'paid' || payCheck.status === 'terminal_paid' || payCheck.status === 'cash_paid' ||
    payCheck.status === 'mix_paid';
}

export const isFirebaseSupported = () => {
  // Check for Service Worker support
  if (!('serviceWorker' in navigator)) {
    console.log('Service Workers are not supported by this browser.');
    return false;
  }

  // Check for Push API support
  if (!('PushManager' in window)) {
    console.log('Push messaging is not supported by this browser.');
    return false;
  }

  // Check for Notification API support
  if (!('Notification' in window)) {
    console.log('Notifications are not supported by this browser.');
    return false;
  }

  // // Check if Notification permission is granted
  // if (Notification.permission === 'denied') {
  //   console.log('User has blocked notifications.');
  //   return false;
  // }

  return true;
};

export function orderRestaurants(slug) {
  const orders = Object.values(store.getState().orders.orderDishes);
  return orders.map((o) => !!o.company && o.company.slug === slug && o.restaurant).filter((o) => !!o);
}